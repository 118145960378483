import '../css/header.css'
import Nav from '../Components/navbar'
const Navbar = () => {


  return (

    <div >
        <div className='photoonhome'>
          <div className='drkblue'>
            

            <Nav/>
            <div className='home' id='home'>
                <div className='hometxt'>
                      <div className='hometxtheading'>Dr. Jai Physio Services (DJPS)</div>
                      <div>
                      Experience transformative rehabilitation that goes beyond traditional methods at Dr. Jai Physio Services (DJPS). 
                      We offer exceptional in-home care that combines cutting-edge techniques with a deep understanding of each patient's 
                      individual needs.<br/>

                      Focussing on holistic wellness, evidence-based practices, and compassionate support, 
                      we empower you to regain strength, mobility, and confidence in your body.<br/>

                      Physiotherapy at Home: Our core service, providing personalized in-home physiotherapy sessions for various conditions.
                      Additional Services at Home: We also offer Chiropractic care,
                      Cupping therapy, 
                      Dry needling therapy, 
                      Dietician and health consultations, 
                      Caretaker and nurse services.               
                      </div>


                <div>

                </div>
            
            
            </div>
            </div>

    </div>
    </div>
    

    </div>
  );
};

export default Navbar;
